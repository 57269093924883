class ImmutableUtils {
  public removeKeyFromObj<Object extends object, Key extends keyof Object>(obj: Object, key: Key): Omit<Object, Key> {
    const { [key]: _, ...rest } = obj;
    return rest;
  }

  public objFromArrayByKey<Object extends object, Key extends keyof Object>(
    arr: Object[],
    key: Key
  ): Record<Key, Object> {
    return arr.reduce((acc, obj) => {
      acc[key] = obj;
      return acc;
    }, {} as Record<Key, Object>);
  }

  public groupArrToObjByKey<Object extends object, Key extends keyof Object>(
    arr: Object[],
    key: Key
  ): Record<Key, Object[]> {
    return arr.reduce((acc, obj) => {
      if (!acc?.[key]) return acc;

      if (acc[key]) acc[key].push(obj);
      else acc[key] = [obj];

      return acc;
    }, {} as Record<Key, Object[]>);
  }
}

export const immutableUtils = new ImmutableUtils();
