import React from "react";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Box, IconButton, Typography } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { CardPriceBlock } from "../cardPriceBlock";
import { ProposalProductCardAddons } from "./ProposalProductCardAddons";
import { ProposalProductsCardTypeChip } from "../ProposalProductsCardTypeChip";
import { ProductCatalogDetails } from "../catalogDetails/ProductCatalogDetails";
import { ProposalProductPricingValidationStatus } from "./ProposalProductPricingValidationStatus";
import { ProductConfig, ProposalProductCardModes } from "../../../types";

interface Props {
  logo?: string;
  product: ProposalProduct;
  onRemoveProduct?: VoidFunction;
  productConfiguration: ProductConfig;
  onRemoveAddon: (title: string) => void;
  onOpenProductDetailsDialog?: VoidFunction;
  mode?: ProposalProductCardModes;
}

export const ProposalProductCard = ({
  logo,
  product,
  onRemoveAddon,
  onRemoveProduct,
  productConfiguration,
  onOpenProductDetailsDialog,
  mode = ProposalProductCardModes.Desking,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box className={classes.titleContainer}>
        {logo && <img className={classes.productLogo} src={logo} alt="Proposal product logo" />}

        <Box className={classes.titleWithValidation}>
          {mode === ProposalProductCardModes.Desking && !product?.aftermarketProduct?.aftermarketVendorApiChannel && (
            <ProposalProductPricingValidationStatus isValid={productConfiguration.isPricingValid} />
          )}

          <Box className={classes.titleWithLogo}>
            <Typography variant="h6" className="productName" style={{ fontSize: 18, lineHeight: 1.3 }}>
              {product.title}
            </Typography>

            {product?.aftermarketProduct?.productCategory && (
              <ProposalProductsCardTypeChip
                productCategory={product.aftermarketProduct.productCategory}
                productType={product.aftermarketProduct.productType}
              />
            )}
          </Box>
        </Box>

        <Box className="actions">
          {onOpenProductDetailsDialog && (
            <IconButton
              size="small"
              onClick={event => {
                event.stopPropagation();
                onOpenProductDetailsDialog();
              }}
            >
              <InfoOutlined color="primary" />
            </IconButton>
          )}

          {onRemoveProduct && (
            <IconButton
              size="small"
              onClick={event => {
                event.stopPropagation();
                onRemoveProduct();
              }}
            >
              <DeleteOutlineIcon color="error" />
            </IconButton>
          )}
        </Box>
      </Box>

      <ProductCatalogDetails product={product} />

      <CardPriceBlock
        type="simple"
        cost={productConfiguration?.cost}
        markup={productConfiguration?.markup}
        retailCost={productConfiguration?.retailCost}
      />

      {!!productConfiguration?.addons?.length && (
        <ProposalProductCardAddons onRemoveAddon={onRemoveAddon} addons={productConfiguration.addons} />
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  card: {
    gap: "8px",
    display: "flex",
    padding: "8px",
    borderRadius: "2px",
    flexDirection: "column",
    backgroundColor: grey["100"],
  },
  titleContainer: {
    gap: "8px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  titleWithValidation: {
    gap: "4px",
    display: "flex",
    alignItems: "flex-start",
  },
  titleWithLogo: {
    display: "flex",
    flexDirection: "column",
  },
  productLogo: {
    width: "36px",
    height: "36px",
  },
});
