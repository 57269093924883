import {
  CalculateMultiplePaymentsCriteriaResponse,
  CalculateMultiplePaymentsPaymentOptionsResponse,
  FinanceProgram,
  Proposal,
  ProposalMenuOption,
} from "@trnsact/trnsact-shared-types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { Nullable, SelectOption } from "../../../global";

export enum OptionsCardsRenderMode {
  Options,
  Menu,
}

export interface MenuSelectsResponse {
  proposalMenus: (SelectOption & { modifiedDateTime: string })[];
}

export interface MenuSelectsVariables {
  accountId: string;
}

export interface ProposalsTableDataResponse {
  proposals: Proposal[];
}

export interface ProposalsTableDataVariables {
  voId: string;
}

export interface FinanceProgramsResponse {
  financePrograms: FinanceProgram[];
}

export interface FinanceProgramsVariables {
  accountId: string;
}

export interface ProposalMenusResponse {
  proposalMenus: ProposalMenuOption[];
}

export interface CalculatingResponse {
  calculateMultiplePayments: {
    criteria: CalculateMultiplePaymentsCriteriaResponse;
    paymentOptions: CalculateMultiplePaymentsPaymentOptionsResponse[];
  }[];
}

export interface CalculatingVariables<Input = any> {
  input: Input;
}

export interface ProposalProductsResponse {
  proposalProducts: ProposalProduct[];
}

export interface CreateProposalResponse {
  createProposalV2: {
    id: string;
    success: boolean;
    errorMessage: string;
    vendorOpportunityId: string;
  };
}

export interface CreateProposalVariables {
  input: {
    sendProposal: boolean;
    financeProgramId: string;
    vendorOpportunityId: string;
    paymentOptions: Record<string, unknown>;
    proposalMenuId?: string;
  };
}

export interface EquipmentsResponse<Equipment = any> {
  equipments: Equipment[];
}

export interface EquipmentsVariables {
  id: string;
  VOId: string;
}
