import gql from "graphql-tag";

const proposalMenuCommonAttributesFragemnt = gql`
  fragment proposalMenuCommonAttributes on ProposalMenu {
    proposalMenuId
    name
    description
    createdDateTime
    modifiedDateTime
    linkedFromCriteria {
      partnerLinkId
      isReadOnly
      account {
        name
      }
    }
    linkedToCriterias {
      partnerLinkId
      isReadOnly
      account {
        name
      }
    }
  }
`;

export const GET_PROPOSAL_MENUS = gql`
  query GetProposalMenus {
    proposalMenus(input: {}) {
      ...proposalMenuCommonAttributes
    }
  }
  ${proposalMenuCommonAttributesFragemnt}
`;

export const GET_PROPOSAL_MENUS_WITH_OPTIONS = gql`
  query GetProposalMenusWithOptions($input: ProposalMenusInput) {
    proposalMenus(input: $input) {
      ...proposalMenuCommonAttributes
      menuOptions {
        name
        ordinal
        description
        proposalMenuOptionId
        titleBgColor
        titleColor
        products {
          cost
          retailCost
          title
          proposalProductId
          thirdPartyUrl
          accountIdCreatedBy
          aftermarketProductToPartnerLinkId
          aftermarketProduct {
            aftermarketProductId
            aftermarketVendorApiChannel
            productName
            productType
            productCategory
            productDescriptionExternal
            criteria
            config {
              criteriaValues
            }
            aftermarketProductCatalogDetails {
              productSummary
              limitationsOfLiability
              eligibilityAndInclusions
              legalDisclaimer
              paragraphDescription
              coverageDetails
            }
          }
          markup {
            type
            markup
          }
        }
        payment {
          paymentAmountPerPeriod
          financeProfit
          dealerMarkup
          paymentAmountPerPeriodWithoutDealerMarkup
          rate
          term
          customerRate
          subsidyAmount
          subsidyPercentage
          aftermarketProfit
          totalProfit
          profitPercentage
          productCost
          amountDueToday
        }
      }
    }
  }
  ${proposalMenuCommonAttributesFragemnt}
`;
