import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ProposalMenu, ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { useHorizontalScroll } from "../../../../../hooks/useHorizontalScroll";
import { MenuOptionCardsProps, MenuOptionToAddEdit } from "../../../types";
import { MenuOptionCard } from "modules/desking";
import { menuConstructorActions, menuConstructorSelectors } from "modules/aftermarketMenuConstructor/model";
import { useModal } from "hooks/useModal";
import { useAftermarketPermissions } from "modules/aftermarketMenuConstructor/hooks/useAftermarketPermissions";
import { ModalsKeys } from "../../../../../global";
import { ProposalProductCardModes } from "modules/desking/types";

export const MenuOptions = ({ isCalculating }: MenuOptionCardsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const proposalMenu = useSelector(menuConstructorSelectors.proposalsMenu) as ProposalMenu;
  const selectedMenuOption = useSelector(menuConstructorSelectors.selectedMenuOption);
  const proposalProductConfigurationsInMenu = useSelector(menuConstructorSelectors.proposalProductConfigurationsInMenu);

  const scrollRef = useHorizontalScroll();
  const { handleOpen: handleAddEditMenuOptionDialogOpen } = useModal(ModalsKeys.AftermarketAddEditMenuOptionDialog);
  const { hasWriteAccess } = useAftermarketPermissions();

  const onDropProduct = (product: ProposalProduct, menuOptionName: string) => {
    dispatch(
      menuConstructorActions.addMenuOptionProduct({
        menuType: menuOptionName,
        product,
      })
    );
  };

  const onRemoveProduct = (removeIndex: number, currentMenuOption: any) => {
    dispatch(
      menuConstructorActions.removeMenuOptionProduct({
        menuType: currentMenuOption.name,
        removeIndex,
      })
    );
  };

  const onEditOption = (currentMenuOption: MenuOptionToAddEdit) => {
    dispatch(
      menuConstructorActions.setMenuOptionToAddEdit({
        currentMenuOption,
      })
    );
    handleAddEditMenuOptionDialogOpen();
  };

  const onRemoveOption = (currentMenuOption: MenuOptionToAddEdit) => {
    dispatch(
      menuConstructorActions.removeMenuOption({
        currentMenuOption,
      })
    );
  };

  const onSelectMenuOption = (optionName: string) => {
    const menuOptionToSet =
      selectedMenuOption?.name === optionName
        ? null
        : proposalMenu.menuOptions?.find((menuOption: ProposalMenuOption) => menuOption.name === optionName)!;
    dispatch(menuConstructorActions.selectMenuOption({ menuOption: menuOptionToSet }));
  };

  const moveMenuOption = (dragIndex: number, hoverIndex: number) => {
    const orderedMenuOptions = [...proposalMenu.menuOptions!];
    orderedMenuOptions.splice(dragIndex, 1);
    orderedMenuOptions.splice(hoverIndex, 0, proposalMenu.menuOptions![dragIndex]);
    dispatch(
      menuConstructorActions.setMenu({
        ...proposalMenu,
        menuOptions: orderedMenuOptions,
      })
    );
  };

  const onRemoveAddon = (product: ProposalProduct, optionName: string, title: string) => {
    const addons = proposalProductConfigurationsInMenu?.[optionName]?.[product.proposalProductId]?.addons ?? [];

    dispatch(
      menuConstructorActions.updateProductConfigurationInMenu({
        productId: product.proposalProductId,
        menuName: optionName,
        data: {
          addons: addons.filter((addon: any) => addon.title !== title),
        },
      })
    );
  };

  return (
    <Box className={classes.cardsContainer} {...({ ref: scrollRef } as any)}>
      {proposalMenu.menuOptions?.map((menuOption: ProposalMenuOption, menuOptionIdx) => (
        <MenuOptionCard
          key={menuOption.proposalMenuOptionId || menuOption.name}
          menuOption={menuOption}
          menuOptionIdx={menuOptionIdx}
          isCalculating={isCalculating}
          moveMenuOption={moveMenuOption}
          selectedMenuOption={selectedMenuOption}
          onEditOption={hasWriteAccess ? onEditOption : undefined}
          onRemoveOption={hasWriteAccess ? onRemoveOption : undefined}
          onDropProduct={hasWriteAccess ? onDropProduct : undefined}
          onRemoveAddon={hasWriteAccess ? onRemoveAddon : undefined}
          onRemoveProduct={hasWriteAccess ? onRemoveProduct : undefined}
          onSelectMenuOption={hasWriteAccess ? onSelectMenuOption : undefined}
          productsConfiguration={proposalProductConfigurationsInMenu?.[menuOption.name] ?? {}}
          mode={ProposalProductCardModes.Constructor}
        />
      ))}
    </Box>
  );
};

const useStyles = makeStyles({
  cardsContainer: {
    gap: "8px",
    display: "flex",
    overflowX: "auto",
    paddingBottom: "6px",
    alignItems: "flex-start",

    "& .actions": {
      gap: "2px",
      display: "flex",
      alignItems: "center",
    },
  },
});
