import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { MenuCards } from "../../../types";
import { MenuOptionCard } from "./MenuOptionCard";
import { MenuOptionDetail } from "./MenuOptionDetail";
import { deskingActions, deskingSelectors } from "../../../model";
import { useModal } from "../../../../../hooks/useModal";
import { ModalsKeys } from "../../../../../global";
import { useMenuOptionTerms } from "../../../hooks";
import { TermsStepperManager } from "../TermsStepperManager";

export const MenuOptionCards = ({ isCalculating, isSectionOpen }: MenuCards) => {
  const classes = useStyles();

  const { handleOpen } = useModal(ModalsKeys.ProductCatalogDialog);

  const dispatch = useDispatch();

  const menuOption = useSelector(deskingSelectors.selectedMenuOption);
  const selectedMenuOption = useSelector(deskingSelectors.selectedMenuOption);
  const menuOptions = useSelector(deskingSelectors.menuByTerm) as ProposalMenuOption[];
  const proposalProductConfigurationsInMenu = useSelector(deskingSelectors.proposalProductConfigurationsInMenu);

  const onDropProduct = (product: ProposalProduct, menuOptionName: string) => {
    dispatch(
      deskingActions.addMenuProduct({
        menuType: menuOptionName,
        product,
      })
    );
  };

  const onRemoveProduct = (removeIndex: number, currentMenuOption: any) => {
    dispatch(
      deskingActions.removeMenuProduct({
        menuType: currentMenuOption.name,
        removeIndex,
      })
    );

    if (selectedMenuOption.name === currentMenuOption.name && currentMenuOption?.products.length === 1) {
      dispatch(deskingActions.selectMenuOption({ optionName: currentMenuOption.name }));
    }
  };

  const onSelectMenuOption = (optionName: string) => {
    dispatch(deskingActions.selectMenuOption({ optionName }));
  };

  const onOpenProductDetailsDialog = (proposalProduct: ProposalProduct) => {
    handleOpen({ proposalProduct });
  };

  const moveMenuOption = (dragIndex: number, hoverIndex: number) => {
    const orderedMenuOptions = [...menuOptions];
    orderedMenuOptions.splice(dragIndex, 1);
    orderedMenuOptions.splice(hoverIndex, 0, menuOptions[dragIndex]);
    dispatch(deskingActions.setMenuOptions({ menuOptions: orderedMenuOptions }));
  };

  const handleRemoveAddon = (product: ProposalProduct, optionName: string, title: string) => {
    const addons = proposalProductConfigurationsInMenu?.[optionName]?.[product.proposalProductId]?.addons ?? [];

    dispatch(
      deskingActions.updateProductConfigurationInMenu({
        productId: product.proposalProductId,
        menuName: optionName,
        data: {
          addons: addons.filter((addon: any) => addon.title !== title),
        },
      })
    );
  };

  const { currentTerm, ...termsManagementProps } = useMenuOptionTerms();

  return (
    <Box className={classes.cardContainer}>
      <TermsStepperManager label={`${currentTerm} Months`} {...termsManagementProps} />

      <Box className={classes.cardsContainer}>
        {!!menuOptions?.length &&
          menuOptions.map((menuOption, menuOptionIdx) => (
            <MenuOptionCard
              key={menuOption.name}
              menuOption={menuOption}
              menuOptionIdx={menuOptionIdx}
              isCalculating={isCalculating}
              onDropProduct={onDropProduct}
              moveMenuOption={moveMenuOption}
              onRemoveProduct={onRemoveProduct}
              onRemoveAddon={handleRemoveAddon}
              onSelectMenuOption={onSelectMenuOption}
              selectedMenuOption={selectedMenuOption}
              onOpenProductDetailsDialog={onOpenProductDetailsDialog}
              productsConfiguration={proposalProductConfigurationsInMenu?.[menuOption.name] ?? {}}
            />
          ))}
      </Box>

      <MenuOptionDetail menuOption={menuOption} isSectionOpen={isSectionOpen} />
    </Box>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    gap: "10px",
    display: "flex",
    flexDirection: "column",
  },
  cardsContainer: {
    gap: "8px",
    display: "flex",
    overflowX: "auto",
    paddingBottom: "6px",
    alignItems: "flex-start",
  },
});
